import { ConfirmPaymentComponent } from '../confirm-payment/confirm-payment.component';
import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DetailCartComponent } from 'src/components/detail-cart/detail-cart.component';
import { ListModalComponent } from 'src/components/list-modal/list-modal.component';
import { ModalComponent } from 'src/components/modal/modal.component';
import { SelectComponent } from 'src/components/select/select/select.component';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { EmittersService } from 'src/utility/services/emitters.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { formatCurrency } from '@angular/common';
import {
    emailRegexValidators,
    regexOnlyNumber,
    regexNumber,
    regexPhone,
} from 'src/utility/functions/regex.service';
import { FormValidators } from 'src/utility/form/form.validators';
import { Constants, environment } from 'src/environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Compressor from 'compressorjs';
import * as moment from 'moment';
import * as Bowser from 'bowser';
import { GoogleAnalyticsService } from 'src/utility/services/google-analitycs.service';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';

declare var $: any;
@Component({
    selector: 'app-view-checkout',
    templateUrl: './view-checkout.component.html',
    styleUrls: ['./view-checkout.component.scss'],
})
export class ViewCheckoutComponent
    implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit
{
    countrySelect: any;
    disabledPhone: boolean = false;
    taxType: any;
    prefixPhone: any = null;
    pbx: string = '';
    noRegisterUser: boolean = false;
    regexNumber = regexPhone;
    regexAnyNumber = regexNumber;
    termsAndConditions: boolean = false;
    @ViewChild('modalTerminos') modalTerminos: ModalComponent;
    isSafari: boolean = false;
    showAppDetail: boolean = false;
    listBilling: any[] = [];
    listCard: any[] = [];
    checkoutForm: FormGroup;
    billingFormManualy: FormGroup;
    telemarketingPaymentForm: FormGroup;
    @ViewChild('listBillingElement') listBillingElement: ListModalComponent;
    @ViewChild('createBillingForm') createBillingForm: ModalComponent;
    @ViewChild('confirmPaymentModal') confirmPaymentModal: ModalComponent;
    // @ViewChild('confirmPaymentModal') modal;
    @ViewChild('createFormCards') createFormCards: ModalComponent;
    @ViewChild('selectCard') selectCard: SelectComponent;
    @ViewChild('detailCart') detailCart: DetailCartComponent;
    @ViewChild('confirmPaymentComponent')
    confirmPaymentComponent: ConfirmPaymentComponent;
    //Modal para editar tarjtas
    @ViewChild('modalEditCard') modalEditCard: ModalComponent;
    //Paneles del acordeón
    @ViewChild('customerInformation')
    customerInformation: AccordionPanelComponent;
    @ViewChild('billingInformation')
    billingInformation: AccordionPanelComponent;
    @ViewChild('methodOfPayment')
    methodOfPayment: AccordionPanelComponent;
    @ViewChild('serviceDetail') serviceDetail: AccordionPanelComponent;
    userLogged: boolean = false;
    userRol = '';
    data: any;
    billingData: any = null;
    dataToConfirm: any[] = [];
    htmlToPay = null;
    showFinish: boolean = false;
    reload: boolean = true;
    collectionAddress: any;
    title: string = 'Editar tarjeta';

    listCardEdit: any[] = [];

    deleteDefault: boolean = false;

    //summary
    numberCard: string = null;

    //forzaPoints
    ForzaPointsDetail = null;

    validatorsCustom = {
        NIT: [Validators.required, FormValidators.matchNit(true)],
        RTN: [Validators.required, FormValidators.matchRTN(true)],
        address: [Validators.required],
        name: [Validators.required],
        email: [Validators.required, Validators.pattern(emailRegexValidators)],
        referenceNumber: [
            Validators.required,
            Validators.pattern(/^(?!0)\d{5,10}$/),
            this.maxNumberValidator(2147483647),
        ],
    };

    maxNumberValidator(max: number) {
        return (control) => {
            const inputValue = control.value;
            if (inputValue && parseInt(inputValue, 10) > max) {
                return { maxNumber: true };
            }
            return null;
        };
    }

    LoaderInfoCustomer: boolean = false;
    dataCustomer: any[] = [];
    customerSelect: any = null;
    srcImg: any = null;
    uploader: FileUploader = new FileUploader({
        url: '',
        disableMultipart: true,
        allowedMimeType: ['image/png', 'image/jpeg'],
        maxFileSize: 1024 * 1024,
    });
    hasBaseDropZoneOver: boolean = false;
    imageComprobante: string = '';
    paymentIsConfirmed: boolean = false;
    paymentIsCompleted: boolean = false;
    orderNumber: string = '';
    cardDataManualy: any = null;
    isInvalidEmail: boolean = true;
    idCurrency: number;
    constructor(
        private fb: FormBuilder,
        private secureStorage: SecureSimpleStorage,
        private connectionApi: ConnectionApiService,
        private sweet: SweetAlert,
        private emitterService: EmittersService,
        private router: Router,
        private authService: AuthUserService,
        private changeDetector: ChangeDetectorRef,
        private googleAnalyticsService: GoogleAnalyticsService,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {
        this.checkoutForm = this.fb.group({
            paymenthMethod: ['', [Validators.required]],
            billingOption: ['', [Validators.required]],
        });
        this.billingFormManualy = this.fb.group({
            phone: [''],
        });
        this.telemarketingPaymentForm = this.fb.group({});
    }

    async ngOnInit() {
        this.idCurrency = environment.multiCountryData[0].idCurrency;
        this.countrySelect = environment.multiCountryData[0].countrySelect;
        this.taxType = environment.multiCountryData[0].taxType;
        if (environment.rol == 'Ventas telemercadeo') {
            this.countrySelect =
                environment.TelemercadeoCountryData[0].countrySelect;
            this.taxType = environment.TelemercadeoCountryData[0].taxType;
        }
        this.userRol = environment.rol;
        this.verifyData();
        this.authService.updateVersion();
        if (this.userRol == 'Nuevo estandar') {
            this.userRol = 'Individual';
        }
        if (this.userRol == 'Individual') {
            await this.getBillingFav();
        }
        setTimeout(() => {
            this.showAppDetail = true;
        }, 100);
        const browser = Bowser.getParser(window.navigator.userAgent);
        const nameBrowser: string = browser.getBrowser().name;
        this.isSafari = nameBrowser.toLowerCase() == 'safari';
        setTimeout(async () => {
            this.pbx = this.encryptedCookieStorageService.get(
                Contract.contactInfo
            );
        }, 500);
    }

    ngAfterViewInit(): void {
        if (this.userRol == 'Ventas telemercadeo') {
            this.customerInformation.isOpen = true;
        } else {
            this.billingInformation.isOpen = true;
        }
        if (this.userRol != 'Individual' || !this.userLogged) {
            let billingData = {
                Address: '',
                Email: '',
                TaxId: '',
                Name: '',
            };
            this.billingFormManualy.valueChanges.subscribe((value) => {
                if (this.customerSelect) {
                    return;
                }
                if (this.billingFormManualy.valid) {
                    billingData.Address =
                        this.getAddressBilling?.get('input')?.value;
                    billingData.Name = this.getNameBilling?.get('input')?.value;
                    billingData.TaxId = this.getNIT?.get('input')?.value;
                    billingData.Email =
                        this.getEmailBilling?.get('input')?.value;
                    this.billingData = billingData;
                } else {
                    this.billingData = null;
                }
            });
        }
    }

    async ngOnDestroy(): Promise<void> {}

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    selectBilling(option: string) {
        if (option == 'FAV') {
            this.listBillingElement.openModal();
        } else if (option == 'CF') {
            if (this.countrySelect.value === 'GT') {
                if (this.detailCart?.amountTotal >= 2500) {
                    this.sweet.errorCriticalAlert(
                        '¡Advertencia!',
                        `En base al artículo 30 numeral 9 del Acuerdo
                    Gubernativo 5-2013 del Reglamento de la Ley del Impuesto al Valor Agregado. Desde Q. 2,500.00 no puede facturar como consumidor final`,
                        null,
                        'warning',
                        'orange'
                    );
                    this.getBillingOption.setValue('');
                    return;
                }
                this.billingData = {
                    Name: 'Consumidor Final',
                    Address: 'Ciudad',
                    TaxId: 'CF',
                };
                this.methodOfPayment.isOpen = true;
            }
            this.billingData = {
                Name: 'Consumidor Final',
                Address: 'Ciudad',
                TaxId: 'CF',
            };

            this.methodOfPayment.isOpen = true;
        }
    }

    changeSelectBilling(event) {
        if (event.value == 'add') {
            this.createBillingForm?.Open();
            $('#createBillingForm').insertAfter($('body'));
            if (!this.billingData) {
                this.getBillingOption.setValue('');
            } else if (
                this.billingData &&
                this.billingData?.TaxId.toLowerCase() == 'cf'
            ) {
                this.getBillingOption.setValue('CF');
            }
            return;
        }
        this.billingData = event.value;
        this.methodOfPayment.isOpen = true;
    }

    closeModalBillingFav() {
        if (!this.billingData) {
            this.getBillingOption.setValue('');
            return;
        }
        if (this.billingData?.TaxId.toLowerCase() == 'cf') {
            this.getBillingOption.setValue('CF');
        }
    }

    changeMethodPayment(name) {
        if (name == 'card') {
            this.getAllCardsAsociate();
        }
    }

    addForm(name: string, form: FormGroup) {
        if (this.userRol == 'Individual') {
            this.checkoutForm.removeControl(name);
            this.checkoutForm.addControl(name, form);
        } else if (this.userRol == 'Ventas telemercadeo' || !this.userLogged) {
            if (name != 'referenceNumber' && name != 'emailUser') {
                this.billingFormManualy.removeControl(name);
                this.billingFormManualy.addControl(name, form);
            } else {
                this.telemarketingPaymentForm.removeControl(name);
                this.telemarketingPaymentForm.addControl(name, form);
            }
        }
    }

    async getBillingFav(idBilling: string = null) {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        const body = {
            path: 'Ecommerce/GetBillingProfile',
            data: {
                Method: 'GetBillingProfile',
                Params: {
                    IdAccount:
                        this.userRol == 'Individual'
                            ? user.ObjectValue[0].Accounts[0].IdAccount
                            : this.customerSelect.IdAccount,
                    IdBilling: -1,
                    Token: user.ObjectValue[0].Token,
                },
            },
        };
        const response = await this.connectionApi.postData(body).toPromise();
        if (!response.ObjectValue) {
            return;
        }
        if (response.ObjectValue.length <= 0) {
            return;
        }
        if (response.ObjectValue[0]?.IdResult === 500) {
            return;
        }

        this.listBilling = [];
        this.listBilling.push({
            text: `<div>
                        <label role="button" class="text-h4 mb-0 pr-2">
                            <strong>Agregar método de facturación</strong>
                        </label>
                        <em class="fa fa-1x fa-plus text-primary"></em>
                    </div>`,
            value: 'add',
        });
        for (const iterator of response.ObjectValue) {
            if (iterator?.TaxId?.toLowerCase() != 'cf') {
                this.listBilling.push({
                    text: `${iterator.Name} - ${iterator.TaxId}`,
                    value: iterator,
                });
                if (idBilling && idBilling == iterator.IdBilling) {
                    this.billingData = iterator;
                    this.methodOfPayment.isOpen = true;
                }
            }
        }
    }

    onClickAddBilling() {
        if (!this.showPayBtn()) {
            this.createBillingForm.Open();
        }
    }

    onClickAddCard() {
        if (this.getPaymenthMethod.value == 'card' && !this.showPayBtn()) {
            this.createFormCards.Open();
        }
    }

    onCloseModal = (value) => {
        this.createBillingForm.Close();
        this.getBillingFav(value.ObjectValue[0].IdBilling);
        this.getBillingOption.setValue('FAV');
    };

    public get getPaymenthMethod() {
        return this.checkoutForm.get('paymenthMethod');
    }
    public get getBillingOption() {
        return this.checkoutForm.get('billingOption');
    }

    async getAllCardsAsociate(idCard = null) {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);

        const body = {
            path: 'Catalog/GetDynamicCatalog',
            data: {
                Method: 'GetDynamicCatalog',
                Params: {
                    TypeMethod: 'GetPaymentMethod',
                    IdAccount: String(
                        user.ObjectValue[0].Accounts[0].IdAccount
                    ),
                    Token: String(user.ObjectValue[0].Token),
                },
            },
        };

        this.listCard = [];
        const response = await this.connectionApi.postData(body).toPromise();

        this.listCard.unshift({
            value: 'Crear nueva tarjeta',
            text: 'Crear nueva tarjeta',
            isTitle: true,
            iconTitle: 'fa fa-plus',
        });

        if (response.ObjectValue) {
            for (const iterator of response?.ObjectValue) {
                const item = {
                    text: iterator.DisplayText,
                    value: iterator,
                    icon: 'fa fa-edit',
                };
                if (idCard == null && iterator.IsDefault) {
                    this.selectCard?.itemSelected(item);
                } else if (iterator.Id == idCard) {
                    this.selectCard?.itemSelected(item);
                }
                this.listCard.push(item);
            }
        } else {
            this.selectCard.markInvalidReset();
        }
    }

    async onChangeEmail(event: any) {
        this.isInvalidEmail = true;
        let email = event.target.value;
        let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let emailValid = pattern.test(email);

        setTimeout(async () => {
            if (email !== '' && emailValid) {
                const body = {
                    path: 'Fac/GetValidEmailUser',
                    data: {
                        Method: 'GetValidEmailUser',
                        Params: {
                            Email: email,
                            CountryId: this.countrySelect.value,
                        },
                    },
                };

                try {
                    const response = await this.connectionApi
                        .postData(body)
                        .toPromise();
                    if (response.StatusCode != 200 || !response.ObjectValue) {
                        return;
                    }

                    if (response.ObjectValue[0].IsValid !== 1) {
                        this.sweet.transactionError(
                            'Correo no válido',
                            'La compra solo es válida en el país de la cuenta. Por favor, utiliza una cuenta registrada en el país de la compra. Para más ayuda, contacta a soporte.'
                        );
                        this.getEmailBilling.reset();
                    } else {
                        this.isInvalidEmail = false;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }, 1500);
    }

    getPrefixPhone(event) {
        this.prefixPhone = event.prefix;
        if (this.prefixPhone == '+504') {
            this.billingFormManualy
                .get('phone')
                .setValidators([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(8),
                    Validators.pattern('(2|3|4|5|6|7|8|9)[0-9]{7}'),
                ]);
            this.billingFormManualy?.get('phone')?.updateValueAndValidity();
        } else {
            this.billingFormManualy
                .get('phone')
                .setValidators([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(8),
                    Validators.pattern('(5|2|3|4|6|7)[0-9]{7}'),
                ]);
            this.billingFormManualy?.get('phone')?.updateValueAndValidity();
        }
    }

    async onClickPay() {
        if (!this.userLogged) {
            //metodo para usuarios no loggeados
            this.getHTML3DSecureGenericUserNoLogged();
        } else {
            if (this.userRol == 'Individual') {
                if (this.getPaymenthMethod.value == 'card') {
                    //metodo para individuales loggeados
                    this.getHTML3DSecureGeneric();
                }
            } else {
                //metodo para telemercadeo
                this.finishTelemarketingCart();
            }
        }
    }

    async getHTML3DSecureGeneric() {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        let totalAmount = this.detailCart?.amountTotal;
        totalAmount = Number(totalAmount.toFixed(2));

        const date = moment();
        const prefixOrderNumber = 'SP00';
        const prefixid = '0';
        const dateOrderNumber = `${date.format('YY')}${date.format(
            'hh'
        )}${date.format('mm')}${date.format('ss')}`;
        const accountDate =
            Number(dateOrderNumber) +
            Number(user.ObjectValue[0].Accounts[0].IdAccount);
        const orderNumber = prefixOrderNumber + prefixid + String(accountDate);
        this.orderNumber = orderNumber;
        let products: any = [];
        products = await this.getProductsFormated();
        const body = {
            path: 'FAC/Request3DSecureTransactionGeneric',
            data: {
                Method: 'Request3DSecureTransactionGeneric',
                Params: {
                    System: 1,
                    IdCustomer: user.ObjectValue[0].Accounts[0].IdCustomer,
                    IdAccount: user.ObjectValue[0].Accounts[0].IdAccount,
                    CardId: this.getCardsCredit.value.value.Id,
                    Ammount: totalAmount,
                    TypeSalePackage: null,
                    IdServiceCart: null,
                    Orders: [
                        {
                            OrderNumber: orderNumber,
                        },
                    ],
                    Token: user.ObjectValue[0].Token,
                    Product: products,
                    TaxId: this.billingData.TaxId,
                    FiscalAddress: this.billingData.Address,
                    TaxName: this.billingData.Name,
                    InvoiceEmail: user.Params.UserName,
                    PhoneNumber:
                        user.ObjectValue[0].Profile[0].nirPhone +
                        user.ObjectValue[0].Profile[0].Phone,
                    IsAutoRenewable: 0,
                    Vaucher: orderNumber,
                    CountryId: this.countrySelect.value,
                    IdCurrency: Number(this.idCurrency),
                },
            },
        };
        const response = await this.connectionApi.postData(body).toPromise();
        if (!response.ObjectValue) {
            this.sweet.transactionError(
                'Pago de carrito',
                'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
            );
            return;
        }
        this.htmlToPay = response.ObjectValue.HTMLFormData;
        let productList: any = '';
        productList = await this.getProductList();
        let productListSeparete = productList.split(',');
        this.dataToConfirm = [
            {
                text: 'Tarjeta',
                value: this.getCardsCredit.value.value.DisplayText,
            },
        ];
        this.dataToConfirm.push({
            text: productListSeparete.length > 1 ? 'Productos' : 'Producto',
            value: productList,
        });
        this.dataToConfirm.push({
            text: 'Total',
            value: this.amountTotal(this.detailCart?.amountTotal),
        });
        this.confirmPaymentModal.Open();
    }

    async getHTML3DSecureGenericUserNoLogged() {
        let randomNumber: number = await this.randomRangeNumber(10000, 999999);
        let totalAmount = this.detailCart?.amountTotal;
        totalAmount = Number(totalAmount.toFixed(2));
        const date = moment();
        const prefixOrderNumber = 'SP00';
        const prefixid = '0';
        const dateOrderNumber = `${date.format('YY')}${date.format(
            'hh'
        )}${date.format('mm')}${date.format('ss')}`;
        const accountDate = Number(dateOrderNumber) + randomNumber;
        const orderNumber = prefixOrderNumber + prefixid + String(accountDate);
        this.orderNumber = orderNumber;
        let products: any = [];
        products = await this.getProductsFormated();
        let IdCurrency = 1;
        switch (this.countrySelect.value) {
            case 'GT':
                IdCurrency = 1;
                break;
            case 'HN':
                IdCurrency = 4;
                break;
            default:
                break;
        }

        const body = {
            path: 'FAC/Request3DSecureTransactionGenericNonLoggedinUser',
            data: {
                Method: 'Request3DSecureTransactionGenericNonLoggedinUser',
                Params: {
                    System: 1,
                    IdCustomer: null,
                    IdAccount: null,
                    CardId: null,
                    IdServiceCart: null,
                    CardholderName: this.cardDataManualy.name,
                    CardNumber: this.cardDataManualy.number.replace(/\s+/g, ''),
                    CardCVV2: this.cardDataManualy.cvv,
                    TypeCardNumber: this.cardDataManualy.TypeCardNumber,
                    CardExpiryDate: this.cardDataManualy.date,
                    Ammount: totalAmount.toString(),
                    Orders: [
                        {
                            OrderNumber: orderNumber,
                        },
                    ],
                    Token: 'user not logged in',
                    Product: products,
                    TaxId: this.billingData.TaxId,
                    FiscalAddress: this.billingData.Address,
                    TaxName: this.billingData.Name,
                    InvoiceEmail: this.getEmailBilling.get('input')?.value,
                    PhoneNumber: this.prefixPhone + this.getPhone.value,
                    IsAutoRenewable: 0,
                    Vaucher: orderNumber,
                    CountryId: this.countrySelect.value,
                    IdCurrency: IdCurrency,
                },
            },
        };

        const response = await this.connectionApi.postData(body).toPromise();
        if (!response.ObjectValue) {
            this.sweet.transactionError(
                'Pago de carrito',
                'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
            );
            return;
        }
        this.htmlToPay = response.ObjectValue.HTMLFormData;
        let cardNumber = this.cardDataManualy.number.split(' ');
        let productList: any = '';
        productList = await this.getProductList();
        let productListSeparete = productList.split(',');
        this.dataToConfirm = [
            {
                text: 'Tarjeta',
                value: `XXXX-XXXX-XXXX-${cardNumber[cardNumber.length - 1]}`,
            },
        ];
        this.dataToConfirm.push({
            text: productListSeparete.length > 1 ? 'Productos' : 'Producto',
            value: productList,
        });
        this.dataToConfirm.push({
            text: 'Total',
            value: this.amountTotal(this.detailCart?.amountTotal),
        });
        this.confirmPaymentModal.Open();
    }

    async getProductList() {
        let element = JSON.stringify(this.detailCart?.listProductsOfCart);
        let productList: any = JSON.parse(element);
        let products: any = [];
        let productsConcat: string = '';
        for (let i = 0; i < productList.length; i++) {
            let idx = products.findIndex(
                (item) =>
                    item?.ProductId == productList[i]?.ProductId &&
                    item?.CatProductName == productList[i]?.CatProductName
            );
            if (idx >= 0) {
                products[idx].quantity++;
            } else {
                productList[i] = { ...productList[i], ...{ quantity: 1 } };
                products.push(productList[i]);
            }
        }
        for (let item of products) {
            productsConcat += `${item.quantity} ${item.CatProductName}, `;
        }
        productsConcat = productsConcat.substring(0, productsConcat.length - 2);
        return productsConcat;
    }

    async finishTelemarketingCart() {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        let totalAmount = this.detailCart?.amountTotal;
        totalAmount = Number(totalAmount.toFixed(2));
        let products: any = [];
        products = await this.getProductsFormated();
        let IdCurrency = 1;
        switch (this.countrySelect.value) {
            case 'GT':
                IdCurrency = 1;
                break;
            case 'HN':
                IdCurrency = 4;
                break;
            default:
                break;
        }
        const body = {
            path: 'FAC/Request3DSecureTransactionGenericTeleMarketMarketPlace',
            data: {
                Method: 'Request3DSecureTransactionGenericTeleMarketMarketPlace',
                Params: {
                    System: 1,
                    IdCustomer: '1',
                    IdAccount: user.ObjectValue[0].Accounts[0]?.IdUser,
                    CardId: 0,
                    Ammount: totalAmount.toString(),
                    TypeSalePackage: null,
                    IdServiceCart: null,
                    Orders: [
                        {
                            OrderNumber: this.getReferenceNumber.value,
                        },
                    ],
                    Token: user.ObjectValue[0].Token,
                    Product: products,
                    TaxId: this.billingData.TaxId,
                    FiscalAddress: this.billingData.Address,
                    TaxName: this.billingData.Name,
                    InvoiceEmail: this.customerSelect
                        ? this.getEmailUser?.value
                        : this.getEmailBilling.get('input')?.value,
                    IsAutoRenewable: 0,
                    Vaucher: this.getReferenceNumber.value,
                    PaymentImage: this.imageComprobante,
                    CountryId: this.countrySelect.value,
                    IdCurrency: IdCurrency,
                },
            },
        };
        try {
            const response = await this.connectionApi
                .postData(body)
                .toPromise();
            if (response?.StatusCode != 200) {
                this.sweet.errorInfoAlert(
                    'Error',
                    'Ocurrió un error al realizar la compra.',
                    'error'
                );
                return;
            }

            if (response?.ObjectValue?.IdResult == 0) {
                this.sweet.errorCriticalAlert(
                    '',
                    `${response?.Description}`,
                    null,
                    'warning',
                    'orange'
                );
                return;
            }
            this.purchaseMade('Telemercadeo', this.getReferenceNumber.value);
            this.billingFormManualy.reset();
            this.telemarketingPaymentForm.reset();
            this.srcImg = null;
            this.sweet.errorInfoAlert(
                '',
                'La compra fue realizada con éxito',
                'success',
                () => {}
            );
            this.emitterService.emitRealoadCart(true);
        } catch (error) {
            this.sweet.errorInfoAlert(
                'Error',
                error.Message ? error.Message : error,
                'error'
            );
        }
    }

    async getProductsFormated() {
        let products: any = [];
        for (const iterator of this.detailCart?.listProductsOfCart) {
            products.push({
                TypeSalePackage:
                    iterator.CatProductName == 'Club Forza'
                        ? 'MEMBERSHIP'
                        : iterator?.CatProductName,
                IdSalePackage: iterator.ProductId.toString(),
                IsGift: iterator?.showGift ? 1 : 0,
                ProductGiftShippingEmail:
                    iterator?.showGift && iterator?.giftEmail != ''
                        ? iterator?.giftEmail
                        : 'NULL',
            });
        }
        return products;
    }

    async randomRangeNumber(min, max): Promise<number> {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    paymentCompleted(event) {
        if (event.ReasonCode == '00') {
            this.showFinish = true;
            if (this.userLogged) {
                this.finishCart();
            } else {
                this.finalizeCartUnregistered();
            }
        } else {
            this.sweet.errorCriticalAlert(
                '',
                'Ha ocurrido un error al realizar la compra, intente de nuevo y si persiste comuníquese con soporte al cliente.'
            );
        }
    }

    validStatusPayment(event) {
        this.paymentIsCompleted = event;
    }

    confirmPayment(event) {
        this.paymentIsConfirmed = event;
    }

    startPayment() {
        this.confirmPaymentComponent.onClickConfirm();
    }

    completePayment() {
        this.confirmPaymentComponent.onClickFinalizar();
    }

    async finishCart() {
        await this.showAlertForBuySuccess();
        await this.secureStorage.save(Contract.numberGuide, 0).toPromise();
        this.emitterService.emitChangeGuideNumbers(true);
        this.emitterService.emitRealoadCart(true);
    }

    async finalizeCartUnregistered() {
        setTimeout(async () => {
            await this.showAlertForBuySuccess();
        }, 100);
    }

    public get getCardsCredit() {
        return this.checkoutForm?.get('cardsCredit')?.get('input');
    }

    closeModalEvent = () => {
        this.confirmPaymentModal.Close();
    };

    disableButton(): boolean {
        if (!this.termsAndConditions && this.userRol != 'Ventas telemercadeo') {
            return true;
        }
        if (this.billingData == null) {
            return true;
        }
        if (this.userLogged) {
            if (this.userRol == 'Individual') {
                if (this.getPaymenthMethod.invalid) {
                    return true;
                }
                if (this.getBillingOption.invalid) {
                    return true;
                }
                if (this.getCardsCredit?.invalid) {
                    return true;
                }
                return this.validStatusProducts();
            } else {
                if (!this.srcImg || this.getReferenceNumber.invalid) {
                    return true;
                }
                return this.validStatusProducts();
            }
        } else {
            if (this.cardDataManualy == null) {
                return true;
            }
            if (this.isInvalidEmail) {
                return true;
            }
            return this.validStatusProducts();
        }
    }

    showPayBtn() {
        if (this.detailCart?.listProductsOfCart?.length == 0) {
            if (this.userLogged) {
                if (this.userRol != 'Ventas telemercadeo') {
                    this.getBillingOption.disable();
                    this.getPaymenthMethod.disable();
                } else {
                    this.billingFormManualy.disable();
                    this.disabledPhone = true;
                    this.telemarketingPaymentForm.disable();
                }
            } else {
                this.billingFormManualy.disable();
                this.disabledPhone = true;
            }
            return true;
        }
        if (this.userLogged) {
            if (this.userRol != 'Ventas telemercadeo') {
                this.getBillingOption.enable();
                this.getPaymenthMethod.enable();
            } else {
                this.disabledPhone = false;
                this.billingFormManualy.enable();
                this.telemarketingPaymentForm.enable();
            }
        } else {
            this.billingFormManualy.enable();
            this.disabledPhone = false;
        }
        return false;
    }

    validStatusProducts(): boolean {
        let validStatusProducts: boolean = false;
        let index: number = -1;
        for (let iterator of this.detailCart?.listProductsOfCart) {
            index++;
            if (iterator.showGift && iterator.isInvalidEmail) {
                validStatusProducts = true;
                break;
            }
        }
        return validStatusProducts;
    }

    changeCards(event) {
        this.getAllCardsAsociate(event.ObjectValue.Id);
        this.createFormCards.Close();
    }

    getTitleConfirmPayment() {
        if (!this.confirmPaymentComponent?.hideData) {
            return 'Confirmar datos';
        }
        if (
            this.confirmPaymentComponent?.hideData &&
            this.confirmPaymentComponent?.showFinish < 2
        ) {
            return 'Procesando...';
        }
        return '';
    }

    selectChangeCard(itemCard) {
        if (itemCard.value == 'Crear nueva tarjeta') {
            setTimeout(() => {
                this.createFormCards.Open();
                this.selectCard.markInvalidReset();
            }, 800);
        }
        this.numberCard = itemCard.text;
        if (itemCard) {
            this.serviceDetail.isOpen = true;
        }
    }

    showModalEditCard(event) {
        this.listCardEdit = [];
        this.listCardEdit.push({
            Id: event.value.Id,
            DisplayText: event.value.DisplayText,
            IsDefault: event.value.IsDefault,
        });
        setTimeout(() => {
            this.deleteDefault = this.listCard.length == 2 ? true : false;
            this.modalEditCard.Open();
        }, 800);
    }

    updateListCard(item) {
        this.modalEditCard.Close();
        this.getAllCardsAsociate();
    }

    whenCloseModal() {
        // this.router.navigate(['/dashboard']);
    }

    amountTotal(x: any, useValidate: boolean = false) {
        let currency;
        if (this.countrySelect.currency == 'GTQ') {
            currency = 'Q. ';
        } else {
            currency = 'L. ';
        }

        if (!useValidate) {
            return formatCurrency(+x, 'en-US', currency);
        }
        if (this.getPaymenthMethod?.value == 'PtsForza') {
            return this.ForzaPointsDetail.PointsNeededForExchange + ' pts.';
        } else {
            return formatCurrency(+x, 'en-US', currency);
        }
    }

    verifyData() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                this.data = JSON.parse(data);
                this.userLogged = true;
            },
            (error) => {
                this.userLogged = false;
                console.error(error);
            }
        );
    }

    /**
     * @description Metodo que recibe los datos de TCR que se ingresan de
     * manera manual cuando el usuario no está loggeado
     * @author Luis Alvarado
     * @date 22/08/2023
     * @param {*} event
     * @memberof ViewCheckoutComponent
     */
    cardAdded(event) {
        if (!event) {
            this.cardDataManualy = null;
        } else {
            this.cardDataManualy = {
                number: event?.numCard?.input,
                name: event?.nameCard?.input,
                date: event?.month?.input?.value + event?.year?.input?.value,
                cvv: event?.ccv?.input,
                TypeCardNumber: event?.TypeCardNumber,
            };
        }
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
        if (!e) {
            this.changeInputFile();
        }
    }

    changeInputFile() {
        try {
            if (this.uploader.queue.length > 0) {
                const file: any =
                    this.uploader.queue[this.uploader.queue.length - 1].file
                        .rawFile;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.srcImg = e.target.result;
                };
                new Compressor(file, {
                    quality: 0.4,
                    success: (result) => {
                        reader.readAsDataURL(result);
                    },
                    error: (error) => {
                        console.error(error.message);
                    },
                });
                this.uploader = new FileUploader({
                    url: '',
                    disableMultipart: true,
                    allowedMimeType: ['image/png', 'image/jpeg'],
                    maxFileSize: 1024 * 1024,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    uploadImage(input: HTMLInputElement) {
        input.click();
    }

    imageCropped(event: ImageCroppedEvent) {
        this.imageComprobante = event.base64;
    }

    addManualNIT() {
        if (this.countrySelect.value === 'GT') {
            if (
                this.getNIT?.get('input')?.value.toLowerCase() == 'cf' &&
                this.detailCart.amountTotal >= 2500
            ) {
                this.sweet.errorCriticalAlert(
                    '¡Advertencia!',
                    `En base al artículo 30 numeral 9 del Acuerdo
                Gubernativo 5-2013 del Reglamento de la Ley del Impuesto al Valor Agregado. Desde Q. 2,500.00 no puede facturar como consumidor final`,
                    null,
                    'warning',
                    'orange'
                );
                this.getNIT?.get('input')?.setValue('');
            }
        }
    }

    // para datos de facturacion ingresados manualmente
    public get getNameBilling() {
        return this.billingFormManualy?.get('name');
    }
    public get getNIT() {
        return this.billingFormManualy?.get('NIT');
    }
    public get getAddressBilling() {
        return this.billingFormManualy?.get('address');
    }
    public get getEmailBilling() {
        return this.billingFormManualy?.get('email');
    }
    public get getPhone() {
        return this.billingFormManualy?.get('phone');
    }

    //para datos de pago de telemercadeo
    public get getReferenceNumber() {
        return this.telemarketingPaymentForm
            ?.get('referenceNumber')
            ?.get('input');
    }

    async showAlertForBuySuccess() {
        const localTime = moment();
        const guatemalaTimezoneOffset = -6;
        const guatemalaTime = localTime
            .utcOffset(guatemalaTimezoneOffset)
            .format('DD/MM/YYYY');
        let numberOfCreditCard = this.userLogged
            ? this.dataToConfirm[0].value.split('-')
            : this.dataToConfirm[0].value.split(' ');
        let lastNumberOfCreditCard =
            numberOfCreditCard[numberOfCreditCard.length - 1];

        const content = `<div style="width: 600px; height: 636px; position: relative">
                            <div
                                style="
                                    position: absolute;
                                    top: 56.9%;
                                    left: 24.9%;
                                    font-size: 17px;
                                    color: #000000;
                                    font-weight: 500;
                                ">
                                ${guatemalaTime}
                            </div>
                            <div
                                style="
                                    position: absolute;
                                    top: 59.7%;
                                    left: 36.5%;
                                    font-size: 17px;
                                    color: #000000;
                                    font-weight: 500;
                                ">  
                            ${this.orderNumber}
                            </div>
                            <div
                                style="
                                    position: absolute;
                                    top: 66%;
                                    left: 20.5%;
                                    font-size: 17px;
                                    color: #000000;
                                    font-weight: 600;
                                    width: 351px;
                                    text-align: right;
                                ">
                                ${this.amountTotal(
                                    this.detailCart?.amountTotal
                                )}
                            </div>
                            <img class="w-100 h-100" src="./assets/img/comprobante.png" alt="" />
                        </div>`;
        if (!this.userLogged) {
            this.secureStorage.save(Contract.numberGuide, 0).subscribe();
            this.emitterService.emitChangeGuideNumbers(true);
        }
        const name = `Comprobante_${this.orderNumber}.png`;
        let message: any = this.messageForAlert(true);
        let showBtn: any = this.messageForAlert(false);
        let result = await this.sweet.newAlert(
            'Compra realizada',
            message,
            null,
            '<i class="bi bi-check-circle icon-success"></i>',
            'success',
            'Disfrutar de mis beneficios',
            '#F24822',
            'Seguir comprando',
            '#22A0DC',
            showBtn,
            'swalWideForAlertSwal',
            true,
            '<i class="bi bi-cloud-download mr-2 mt-1"></i> Descargar comprobante',
            '#27c24c',
            content,
            name,
            'contentForAlert'
        );
        if (result.isConfirmed) {
            this.secureStorage
                .save(Contract.routeActive, '/' + this.countrySelect.value)
                .subscribe();
            this.router.navigate(['/' + this.countrySelect.value]);
            const ventana = window.open(
                environment.portalRoute +
                    '/design/individual/activar-consultar-membresias-suscripciones',
                '_blank'
            );
        } else {
            this.secureStorage
                .save(Contract.routeActive, '/' + this.countrySelect.value)
                .subscribe();
            this.router.navigate(['/' + this.countrySelect.value]);
        }
    }

    showModaltermAndCondition() {
        if (!this.termsAndConditions) {
            this.modalTerminos.Open();
        }
    }

    redirectTo() {
        let path = 'terminos-condiciones/';
        this.secureStorage.save(Contract.routeActive, path).toPromise();
        window.open(path);
    }

    purchaseMade(user, transactionNumber) {
        this.googleAnalyticsService.sendEvent(
            'purchase',
            'Compra',
            user,
            transactionNumber
        );
    }

    nextOption(goTo: number) {
        if (goTo == 2) {
            this.methodOfPayment.isOpen = true;
        } else {
            this.serviceDetail.isOpen = true;
        }
    }

    disabledBtnNext(option) {
        if (option == 1) {
            if (this.billingFormManualy.valid) {
                return false;
            } else {
                return true;
            }
        } else {
            if (this.userLogged) {
                if (this.srcImg && this.getReferenceNumber.valid) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (this.cardDataManualy != null) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }

    async searchCustomer() {
        if (this.getEmailUser.invalid || this.LoaderInfoCustomer) {
            return;
        }
        this.LoaderInfoCustomer = true;
        setTimeout(async () => {
            this.noRegisterUser = false;
            this.billingFormManualy.reset();
            this.changeEmail();
            const userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
            const user = JSON.parse(userString);
            const body = {
                path: 'Portal/GetClientDataByEmail',
                data: {
                    Method: 'GetClientDataByEmail',
                    Params: {
                        UserId: user.ObjectValue[0].Accounts[0].IdUser,
                        ClientUserEmail: this.getEmailUser.value,
                        CountryId: this.countrySelect.value,
                    },
                },
            };

            try {
                this.dataCustomer = [];
                this.customerSelect = null;
                const response = await this.connectionApi
                    .postData(body, true)
                    .toPromise();
                if (response.ObjectValue) {
                    if (response.ObjectValue[0]) {
                        this.customerSelect = response.ObjectValue[0];
                        this.getBillingFav();
                        this.dataCustomer.push({
                            text: 'Nombre:',
                            value: response.ObjectValue[0].CustomerName,
                            type: 'neutral',
                        });
                        this.dataCustomer.push({
                            text: 'Teléfono:',
                            value:
                                response.ObjectValue[0].NirPhone +
                                ' ' +
                                response.ObjectValue[0].CustomerPhone,
                            type: 'neutral',
                        });
                    }
                } else {
                    if (response.StatusCode == 204) {
                        if (
                            response.Description ==
                            'Datos de usuario no existen'
                        ) {
                            this.noRegisterUser = true;
                            this.getEmailBilling
                                ?.get('input')
                                ?.setValue(this.getEmailUser.value);
                        } else {
                            this.sweet.transactionError(
                                'Correo no válido',
                                'La compra solo es válida en el país de la cuenta. Por favor, utiliza una cuenta registrada en el país de la compra. Para más ayuda, contacta a soporte.'
                            );
                            this.getEmailUser.reset();
                        }
                    }
                }
                this.LoaderInfoCustomer = false;
                this.billingInformation.isOpen = true;
            } catch (error) {
                this.LoaderInfoCustomer = false;
            }
            this.LoaderInfoCustomer = false;
        }, 500);
    }

    public get getEmailUser() {
        return this.telemarketingPaymentForm?.get('emailUser')?.get('input');
    }

    changeEmail() {
        this.billingData = null;
        this.dataCustomer = [];
        this.customerSelect = null;
        this.listBilling = [];
        this.getBillingOption.setValue('');
    }
    messageForAlert(returnMessage: boolean) {
        let showBtn: boolean = false;
        let message: string = '';
        let includeGifts: boolean = false;
        let includeNoGifts: boolean = false;
        let emailsForGift: any = [];
        let emailsForGiftConcat: string = '';
        for (let item of this.detailCart?.listProductsOfCart) {
            if (!item?.showGift && this.userLogged) {
                showBtn = true;
            }
            if (item?.showGift) {
                includeGifts = true;
                let idx = emailsForGift.findIndex(
                    (email) => email == item?.giftEmail
                );
                if (idx < 0) {
                    emailsForGift.push(item?.giftEmail);
                }
            } else {
                includeNoGifts = true;
            }
        }
        for (let item of emailsForGift) {
            emailsForGiftConcat += `${item}, `;
        }
        emailsForGiftConcat = emailsForGiftConcat.substring(
            0,
            emailsForGiftConcat.length - 2
        );
        if (!includeGifts && includeNoGifts) {
            message = `Gracias por tu compra. Recibirás un correo electrónico con los detalles y las instrucciones para disfrutar de tus beneficios. </br></br>
                ¿Tienes dudas de tu compra? Comunícate con nosotros al <a href="tel:${this.pbx}">PBX: ${this.pbx}</a>  y con gusto te apoyaremos.`;
        } else if (!includeNoGifts && includeGifts) {
            message = `Gracias por tu compra. <strong>${emailsForGiftConcat}</strong> recibir${
                emailsForGift.length > 1 ? 'án' : 'á'
            } un correo  electrónico con los detalles del regalo y las instrucciones para disfrutar de sus beneficios. </br></br>
            ¿Tienes dudas de tu compra? Comunícate con nosotros al <a href="tel:${
                this.pbx
            }">PBX: ${this.pbx}</a>  y con gusto te apoyaremos.`;
        } else {
            message = `Gracias por tu compra. Recibirás un correo electrónico con los detalles y las instrucciones para disfrutar de tus beneficios </br></br>
            Además, <strong>${emailsForGiftConcat}</strong> también recibir${
                emailsForGift.length > 1 ? 'án' : 'á'
            } un correo electrónico  con los detalles del regalo y las instrucciones para disfrutar de sus beneficios. </br></br>
            ¿Tienes dudas de tu compra? Comunícate con nosotros al <a href="tel:${
                this.pbx
            }">PBX: ${this.pbx}</a>  y con gusto te apoyaremos.`;
        }
        return returnMessage ? message : showBtn;
    }
}
